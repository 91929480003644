import React from "react";
import styled from "styled-components";
import HeroBG from "../../assets/bg-img.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Myself from "../../assets/myself.png"

const skills = [
  "HTML",
  "CSS",
  "Tailwind CSS",
  "Bootstrap",
  "Javascript",
  "Typescript",
  "Redux",
  "React JS",
  "NEXT JS",
  "Express JS",
  "ASP.NET API",
  "ASP.NET MVC",
  "Photoshop",
  "Illustrator",
  "Mongo DB",
  "Ms SQL",
];

const About = () => {
  return (
    <AboutPage>
      <div className="overlay"></div>
      <div className="about-container">
        <div className="about-heading">
          <h1 className="heading">About Obinna</h1>
        </div>
        <div className="headingdiv">
          <p className="aboutMe" data-aos="fade-up" data-aos-delay="100">
            I started my web development journey in 2018 and since then i have
            been passionate with it. i have 6 years as a frontend dev and dived
            into backend dev in late 2020 since i was working heavily with
            backend developers.. I thrive on turning ideas into interactive and
            visually appealing websites that deliver exceptional user
            experiences. As a developer, I am driven by the desire to create
            elegant and efficient solutions that meet the needs of clients and
            end-users alike. I possess a strong foundation in front-end
            technologies such as HTML, CSS, and JavaScript, and I am well-versed
            in utilizing popular framework like React to build dynamic and
            responsive user interfaces. On the back-end, I have expertise in
            languages like Node.js and ASP.Net, along with experience working
            with databases such as MsSQL while using .NET and MongoDB while
            working as a MERN stack developer. Additionally, my proficiency in
            version control systems like Git ensures efficient collaboration and
            code management.
          </p>{" "}
          <div className="abtMe">
            <div
              className="imagediv"
              data-aos="zoom-in-up"
              data-aos-delay="300"
            >
              <LazyLoadImage
                // src="https://res.cloudinary.com/ddykl9wav/image/upload/v1686839961/Ishmael_dqbvym.jpg"
                src={Myself}
                alt="logo"
                effect="blur"
              />
            </div>
            <div className="abtWrite">
              <div className="headingdiv">
                <p data-aos="fade-up" data-aos-delay="100">
                  My passion for art and graphics design complements my web
                  development skills, allowing me to craft visually engaging
                  user experiences. I have a strong command of design tools like
                  Adobe Creative Suite, enabling me to create eye-catching
                  layouts, striking visuals, and compelling branding elements.
                  Whether it's designing logos, illustrations, or user
                  interfaces, I strive to create designs that leave a lasting
                  impact on users.
                </p>
              </div>
            </div>
          </div>{" "}
        </div>
        <div className="skills">
          {skills.map((skill, index) => (
            <p key={index} data-aos="zoom-in-up" data-aos-delay="100">
              {skill}
            </p>
          ))}
        </div>
      </div>
    </AboutPage>
  );
};

const AboutPage = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  overflow: hidden;
  background-image: url(${HeroBG});
  background-size: cover;
  padding: 2rem 10rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
  }
  .about-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 10;
    font-family: "Jost", system-ui;
    padding: 2rem;
    width: 75%;
    border-radius: 0.5rem;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
      5px 5px 5px 4px rgba(2, 2, 2, 0.15);
    P {
      color: wheat;
      text-align: justify;
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    }
    .imagediv {
      width: 15%;
      overflow: hidden;
      margin: 0.5rem auto;
      border-radius: 0.5rem;
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .skills {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    animation: blink 3s infinite;
  }
  .skills p {
    border: 2px solid wheat;
    width: 8rem;
    text-align: center;
    padding: 0.2rem 0;
    border-radius: 0.5rem;
    font-weight: 400;
    font-family: "Jost", system-ui;
    box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
      5px 5px 5px 4px rgba(2, 2, 2, 0.15);
  }
  @keyframes blink {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  @media screen and (max-width: 1300px) {
    width: 100%;
    height: fit-content;
    min-height: 130vh;
    overflow: hidden;
    background-image: url(${HeroBG});
    background-size: cover;
    padding: 1rem;
    position: relative;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .about-container {
      position: absolute;
      width: 90%;
      height: fit-content;
      z-index: 10;
      top: 10%;
      left: 50%;
      transform: translateX(-50%);
      font-family: "Jost", system-ui;
      background: rgba(255, 255, 255, 0.2);
      padding: 2rem 1rem;
      border-radius: 0.5rem;
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      P {
        color: wheat;
        text-align: justify;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      }
      .imagediv {
        width: 15%;
        overflow: hidden;
        margin: 0.5rem auto;
        border-radius: 0.5rem;
        box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
          5px 5px 5px 4px rgba(2, 2, 2, 0.15);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .skills {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      gap: 1rem;
      margin-top: 1rem;
      animation: blink 3s infinite;
    }
    .skills p {
      border: 2px solid wheat;
      width: 8rem;
      text-align: center;
      padding: 0.2rem 0;
      border-radius: 0.5rem;
      font-weight: 400;
      font-family: "Jost", system-ui;
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
    }
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    background-image: url(${HeroBG});
    background-size: cover;
    padding: 1rem;
    position: relative;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .about-container {
      position: absolute;
      width: 90%;
      height: fit-content;
      z-index: 10;
      top: 10%;
      left: 50%;
      transform: translateX(-50%);
      font-family: "Jost", system-ui;
      background: rgba(255, 255, 255, 0.2);
      padding: 2rem 1rem;
      border-radius: 0.5rem;
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      P {
        color: wheat;
        text-align: justify;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      }
      .imagediv {
        width: 15%;
        overflow: hidden;
        margin: 0.5rem auto;
        border-radius: 0.5rem;
        box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
          5px 5px 5px 4px rgba(2, 2, 2, 0.15);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .skills {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      gap: 1rem;
      margin-top: 1rem;
      animation: blink 3s infinite;
    }
    .skills p {
      border: 2px solid wheat;
      width: 8rem;
      text-align: center;
      padding: 0.2rem 0;
      border-radius: 0.5rem;
      font-weight: 400;
      font-family: "Jost", system-ui;
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
    }
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    background-image: url(${HeroBG});
    background-size: cover;
    padding: 1rem;
    position: relative;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .about-container {
      position: absolute;
      width: 90%;
      height: fit-content;
      z-index: 10;
      top: 10%;
      left: 50%;
      transform: translateX(-50%);
      font-family: "Jost", system-ui;
      background: rgba(255, 255, 255, 0.2);
      padding: 2rem 1rem;
      border-radius: 0.5rem;
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      P {
        color: wheat;
        text-align: justify;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      }
      .imagediv {
        width: 15%;
        overflow: hidden;
        margin: 0.5rem auto;
        border-radius: 0.5rem;
        box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
          5px 5px 5px 4px rgba(2, 2, 2, 0.15);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .skills {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      gap: 1rem;
      margin-top: 1rem;
      animation: blink 3s infinite;
    }
    .skills p {
      border: 2px solid wheat;
      width: 8rem;
      text-align: center;
      padding: 0.2rem 0;
      border-radius: 0.5rem;
      font-weight: 400;
      font-family: "Jost", system-ui;
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
    }
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: fit-content;
    min-height: 150vh;
    overflow: hidden;
    background-image: url(${HeroBG});
    background-size: cover;
    padding: 0rem 1.5rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .about-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 10;
      font-family: "Jost", system-ui;
      padding: 2rem 2rem;
      width: 100%;
      border-radius: 0.5rem;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      P {
        color: wheat;
        text-align: justify;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      }
      .imagediv {
        width: 15%;
        overflow: hidden;
        margin: 0.5rem auto;
        border-radius: 0.5rem;
        box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
          5px 5px 5px 4px rgba(2, 2, 2, 0.15);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .skills {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      margin-top: 1rem;
      animation: blink 3s infinite;
    }
    .skills p {
      border: 2px solid wheat;
      width: 8rem;
      text-align: center;
      padding: 0.2rem 0;
      border-radius: 0.5rem;
      font-weight: 400;
      font-family: "Jost", system-ui;
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
    }
    @keyframes blink {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    height: fit-content;
    min-height: 180vh;
    overflow: auto;
    background-image: url(${HeroBG});
    background-size: cover;
    padding: 1rem;
    position: relative;
    .overlay {
      width: 100%;
      height: 100%;
      min-height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .about-container {
      position: relative;
      width: 100%;
      height: fit-content;
      z-index: 10;
      /* top: 10%;
      left: 0%;
      transform: translateX(-50%); */
      font-family: "Jost", system-ui;
      background: rgba(255, 255, 255, 0.2);
      padding: 1rem;
      border-radius: 0.5rem;
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      P {
        color: wheat;
        text-align: justify;
        font-size: 0.8rem;
      }
      .imagediv {
        width: 30%;
        overflow: hidden;
        margin: 0.5rem auto;
        border-radius: 0.5rem;
        box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
          5px 5px 5px 4px rgba(2, 2, 2, 0.15);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .skills {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      margin-top: 1rem;
      animation: blink 3s infinite;
    }
    .skills p {
      border: 2px solid wheat;
      width: 9rem;
      text-align: center;
      padding: 0.2rem 0;
      border-radius: 0.5rem;
      font-weight: 400;
      font-size: 0.8rem;
      font-family: "Jost", system-ui;
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
    }
  }
  @media screen and (max-width: 380px) {
    width: 100%;
    height: fit-content;
    min-height: 200vh;
    overflow: auto;
    background-image: url(${HeroBG});
    background-size: cover;
    padding: 1rem;
    position: relative;
    .overlay {
      width: 100%;
      height: 100%;
      min-height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .about-container {
      position: relative;
      width: 100%;
      height: fit-content;
      z-index: 10;
      /* top: 10%;
      left: 0%;
      transform: translateX(-50%); */
      font-family: "Jost", system-ui;
      background: rgba(255, 255, 255, 0.2);
      padding: 1rem;
      border-radius: 0.5rem;
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
      P {
        color: wheat;
        text-align: justify;
        font-size: 0.8rem;
      }
      .imagediv {
        width: 30%;
        /* display: none; */
        overflow: hidden;
        margin: 0.5rem auto;
        border-radius: 0.5rem;
        box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
          5px 5px 5px 4px rgba(2, 2, 2, 0.15);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .skills {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      margin-top: 1rem;
      animation: blink 3s infinite;
    }
    .skills p {
      border: 2px solid wheat;
      width: 7rem;
      text-align: center;
      padding: 0.2rem 0;
      border-radius: 0.5rem;
      font-weight: 400;
      font-size: 0.8rem;
      font-family: "Jost", system-ui;
      box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
        5px 5px 5px 4px rgba(2, 2, 2, 0.15);
    }
  }
`;
export default About;
