import React from "react";
import styled from "styled-components";
import HeroBG from "../../assets/bg-img.jpg";
import {
  FaGithub,
  FaTwitter,
  FaWhatsapp,
  FaTiktok,
  FaLinkedinIn, 
} from "react-icons/fa";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <Hero>
      <div className="overlay"></div>
      <div className="hero-content">
        <div className="name">
          {" "}
          <h1>Obinna Ishmael Iloanya</h1>
        </div>
        <div className="title">
          <h3>Fullstack Developer (Frontend Heavy)</h3>
        </div>
        <div className="socials">
          <Link to="https://www.github.com/obilomania" target="_blank">
            <FaGithub />
          </Link>
          <Link to="https://wa.me/message/S3UKGCJ2JXAZH1" target="_blank">
            <FaWhatsapp />
          </Link>
          <Link to="https://x.com/obilomania" target="_blank">
            <FaTwitter />
          </Link>
          <Link to="https://www.tiktok.com/@obilomania" target="_blank">
            <FaTiktok />
          </Link>
          <Link to="https://www.linkedin.com/in/obilomania" target="_blank">
            <FaLinkedinIn />
          </Link>
        </div>
      </div>
    </Hero>
  );
};

const Hero = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  overflow: hidden;
  background-image: url(${HeroBG});
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
  }
  .hero-content {
    font-family: "Jost", system-ui;
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: wheat;
    padding: 2rem 4rem;
    border-radius: 0.5rem;
    box-shadow: -5px 5px 5px 4px rgba(0, 0, 0, 0.1),
      5px 5px 5px 4px rgba(2, 2, 2, 0.15);

    h1 {
      font-size: 3rem;
      transition: all 0.5s ease-in-out;
    text-align:center;
      &:hover {
        color: white;
        transition: all 0.5s ease-in-out;
      }
    }
    h3 {
      font-size: 2rem;
      letter-spacing: 0.2rem;
      transition: all 0.5s ease-in-out;
      text-align:center;
      &:hover {
        color: white;
        transition: all 0.5s ease-in-out;
      }
    }
    .socials a:nth-child(1) {
      animation-delay: 0s;
    }

    .socials a:nth-child(2) {
      animation-delay: 0.2s;
    }

    .socials a:nth-child(3) {
      animation-delay: 0.4s;
    }

    .socials a:nth-child(4) {
      animation-delay: 0.6s;
    }

    .socials a:nth-child(5) {
      animation-delay: 0.8s;
    }
    @keyframes float {
      0% {
        transform: translateY(0);
        color: white;
      }
      50% {
        transform: translateY(-15px);
        color: darkgray; /* Adjust the height of the float */
      }
      100% {
        transform: translateY(0);
      }
    }
    .socials {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.5s ease-in-out;
      margin-top: 2rem;

      a {
        color: wheat;
        font-size: 1.5rem;
        animation: float 3s ease-in-out infinite;
        &:hover {
          color: white;
          transition: all 0.5s ease-in-out;
        }
      }
    }
  }
  @media screen and (max-width: 1300px) {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    background-image: url(${HeroBG});
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .hero-content {
      font-family: "Jost", system-ui;
      background: rgba(255, 255, 255, 0.2);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      color: wheat;
      padding: 2rem 1rem;
      border-radius: 0.5rem;
      width: 90%;

      h1 {
        font-size: 3.5rem;
        transition: all 0.5s ease-in-out;

        &:hover {
          color: white;
          transition: all 0.5s ease-in-out;
        }
      }
      h3 {
        font-size: 2rem;
        letter-spacing: 0.2rem;
        transition: all 0.5s ease-in-out;

        &:hover {
          color: white;
          transition: all 0.5s ease-in-out;
        }
      }
      .socials {
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.5s ease-in-out;
        margin-top: 2rem;
        width: 80%;

        a {
          color: wheat;
          font-size: 1.5rem;
          &:hover {
            color: white;
            transition: all 0.5s ease-in-out;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    background-image: url(${HeroBG});
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .hero-content {
      font-family: "Jost", system-ui;
      background: rgba(255, 255, 255, 0.2);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      color: wheat;
      padding: 2rem 1rem;
      border-radius: 0.5rem;
      width: 90%;

      h1 {
        font-size: 3.5rem;
        transition: all 0.5s ease-in-out;

        &:hover {
          color: white;
          transition: all 0.5s ease-in-out;
        }
      }
      h3 {
        font-size: 2rem;
        letter-spacing: 0.2rem;
        transition: all 0.5s ease-in-out;

        &:hover {
          color: white;
          transition: all 0.5s ease-in-out;
        }
      }
      .socials {
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.5s ease-in-out;
        margin-top: 2rem;
        width: 80%;

        a {
          color: wheat;
          font-size: 1.5rem;
          &:hover {
            color: white;
            transition: all 0.5s ease-in-out;
          }
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    background-image: url(${HeroBG});
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .hero-content {
      font-family: "Jost", system-ui;
      background: rgba(255, 255, 255, 0.2);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      color: wheat;
      padding: 2rem 1rem;
      border-radius: 0.5rem;
      width: 90%;

      h1 {
        font-size: 3rem;
        transition: all 0.5s ease-in-out;

        &:hover {
          color: white;
          transition: all 0.5s ease-in-out;
        }
      }
      h3 {
        font-size: 1.8rem;
        letter-spacing: 0.2rem;
        transition: all 0.5s ease-in-out;

        &:hover {
          color: white;
          transition: all 0.5s ease-in-out;
        }
      }
      .socials {
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.5s ease-in-out;
        margin-top: 2rem;
        width: 80%;

        a {
          color: wheat;
          &:hover {
            color: white;
            transition: all 0.5s ease-in-out;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    background-image: url(${HeroBG});
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .hero-content {
      font-family: "Jost", system-ui;
      background: rgba(255, 255, 255, 0.2);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0rem;
      color: wheat;
      padding: 2rem 1rem;
      border-radius: 0.5rem;
      width: 90%;

      h1 {
        font-size: 2rem;
        transition: all 0.5s ease-in-out;

        &:hover {
          color: white;
          transition: all 0.5s ease-in-out;
        }
      }
      h3 {
        font-size: 1.5rem;
        letter-spacing: 0.2rem;
        transition: all 0.5s ease-in-out;

        &:hover {
          color: white;
          transition: all 0.5s ease-in-out;
        }
      }
      .socials {
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.5s ease-in-out;
        margin-top: 2rem;
        width: 80%;

        a {
          color: wheat;
          font-size: 1rem;
          &:hover {
            color: white;
            transition: all 0.5s ease-in-out;
          }
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    background-image: url(${HeroBG});
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .hero-content {
      font-family: "Jost", system-ui;
      background: rgba(255, 255, 255, 0.2);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0rem;
      color: wheat;
      padding: 2rem 1rem;
      border-radius: 0.5rem;
      width: 90%;

      h1 {
        font-size: 1.5rem;
        transition: all 0.5s ease-in-out;

        &:hover {
          color: white;
          transition: all 0.5s ease-in-out;
        }
      }
      h3 {
        font-size: 1rem;
        letter-spacing: 0.2rem;
        transition: all 0.5s ease-in-out;

        &:hover {
          color: white;
          transition: all 0.5s ease-in-out;
        }
      }
      .socials {
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.5s ease-in-out;
        margin-top: 2rem;
        width: 80%;

        a {
          color: wheat;
          &:hover {
            color: white;
            transition: all 0.5s ease-in-out;
          }
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    background-image: url(${HeroBG});
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    .hero-content {
      font-family: "Jost", system-ui;
      background: rgba(255, 255, 255, 0.2);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0rem;
      color: wheat;
      padding: 2rem 1rem;
      border-radius: 0.5rem;
      width: 90%;

      h1 {
        font-size: 1.5rem;
        transition: all 0.5s ease-in-out;

        &:hover {
          color: white;
          transition: all 0.5s ease-in-out;
        }
      }
      h3 {
        font-size: 1rem;
        letter-spacing: 0.2rem;
        transition: all 0.5s ease-in-out;

        &:hover {
          color: white;
          transition: all 0.5s ease-in-out;
        }
      }
      .socials {
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.5s ease-in-out;
        margin-top: 2rem;
        width: 80%;

        a {
          color: wheat;
          &:hover {
            color: white;
            transition: all 0.5s ease-in-out;
          }
        }
      }
    }
  }
`;
export default HeroSection;
